<template>
  <v-app>
    <v-main>
      <v-container>
        <h1>MT16 Player Cards</h1>
        <v-text-field
          v-model="search"
          label="Search"
          prepend-icon="mdi-magnify"
        ></v-text-field>
        <v-row>
          <v-col
            cols="12"
            :sm="6"
            :md="4"
            :lg="3"
            v-for="player of playersFiltered"
            :key="player.name"
            class="player-card"
            @click="openDialog(player)"
          >
            <player-card v-tilt :info="player"></player-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>

    <v-dialog v-model="dialog" width="813"
      ><player-card :info="dialogPlayer"></player-card
    ></v-dialog>
  </v-app>
</template>

<style>
.player-card {
  line-height: 0;
}
h1 {
  text-align: center;
  font-weight: 200;
  font-size: 6em;
}
.player-card:hover {
  cursor: pointer;
  z-index: 10;
}

.player-card canvas {
  width: 100%;
}

.v-dialog {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.v-dialog::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
</style>

<script>
import PlayerCard from "./components/PlayerCard";
export default {
  components: {
    PlayerCard,
  },

  mounted() {
    fetch("https://nodecg-mfh.mysteryfun.house/card-api")
      .then((response) => response.json())
      .then((json) => {
        console.log(json);
        let out = "";
        for (let player of json) {
          out = out + player.avatar + "\n";
        }

        console.log(out);

        return (this.players = json);
      });
  },

  methods: {
    openDialog(player) {
      if (window.innerWidth > 960) {
        this.dialogPlayer = player;
        this.dialog = true;
      }
    },
  },

  computed: {
    playersFiltered() {
      let players = this.players;

      players = players.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });

      if (this.search != "") {
        players = players.filter((player) => {
          return player.name.toLowerCase().includes(this.search.toLowerCase());
        });
      }

      return players;
    },
  },

  data() {
    return {
      players: [],
      dialogPlayer: null,
      search: "",
      dialog: false,
    };
  },
};
</script>
